
/**
 * Device list tab view,
 * the table of devices int he current project,
 * all formatting and display information for the device list.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Watch, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions, ActionMethod, MutationMethod, mapMutations } from "vuex";

import Project from "@/model/Project";
import { SingleDeviceState } from "@/model/ModelSectionState";
import { SectionStateDTO } from "@/model/ModelSectionState";
import Section, { SingleProjectDeviceInDeviceList } from "@/model/ModelSection";
import KilometrageFromatted from "@/mixins/Project/KilometrageFromatted";
import SingleBoardInformationModal from "@/components/project/SingleProject/Schematic/InformationModal/SingleBoardInformationModal.vue";

interface SingleDevice {
  serialNumber: string;
}

@Component({
  name: "DeviceListTabView",
  computed: {
    ...mapGetters(["getAllDevices"]),
    ...mapGetters("projects", {
      CurrentProject: "GetCurrentProject",
      allSectionsInTheProject: "GetAllSectionInProject",
      allSectionStates: "GetProjectSectionsStatesForMap",
      allOppositeSection: "GetAllOppositeSections"
    })
  },
  methods: {
    ...mapActions("projects", {
      GET_LATEST_STATE: "GetAllSectionStatus"
    }),
    ...mapMutations("projects", {
      SET_MODAL_OPEN: "SET_MODAL_OPEN"
    })
  },
  components: {
    SingleBoardInformationModal
  }
})
export default class DeviceListTabView extends Mixins(KilometrageFromatted) {
  /******Props *****/
  listOfDevices: Array<SingleProjectDeviceInDeviceList> = [];

  /******Vuex *****/
  readonly CurrentProject!: Project;
  readonly allSectionStates!: any;
  readonly allSectionsInTheProject!: Array<Section>;

  readonly getAllDevices!: Array<SingleDevice>;
  private GET_LATEST_STATE!: ActionMethod;
  private SET_MODAL_OPEN!: MutationMethod;

  /******localState *****/
  labelsForDeviceList: Array<string> = [
    "orderedSection",
    "id",
    "position",
    "side",
    "picture",
    "brightness",
    "charging_current",
    "discharging_current",
    "solar",
    "battery",
    "state"
  ];
  loading = false;
  modal = {
    sectionId: 0,
    position: "deviceOne"
  };
  /*----------  Vue watchers  ----------*/
  @Watch("CurrentProject")
  HandleSectionListUpdate() {
    this.setDeviceList();
  }

  @Watch("allSectionStates")
  formatDevicesInformation() {
    this.allSectionStates.forEach(({ state }: SectionStateDTO) => {
      let deviceOne = state.stateDetails.deviceStateOne;
      let deviceTwo = state.stateDetails.deviceStateTwo;
      if (deviceOne.mainboardUid) {
        this.listOfDevices = this.listOfDevices.map((item) => {
          if (item.id == deviceOne.mainboardUid) {
            let formated = this.updateDevice(deviceOne);
            item = {
              ...item,
              ...formated
            };
          }
          return item;
        });
      }
      if (deviceTwo.mainboardUid) {
        this.listOfDevices = this.listOfDevices.map((item) => {
          if (item.id == deviceTwo.mainboardUid) {
            let formated = this.updateDevice(deviceTwo);
            item = {
              ...item,
              ...formated
            };
          }
          return item;
        });
      }
    });
  }
  /***
   * Todo This sould be handle better when the implementationm with web sockets
   * the device list jumps up and down when removing a device from the section 
   * We need to keep track of the devices in the section or generate a new device list and compare with the old one 
   * so that way we dont have  to reset it.
  @Watch("allSectionsInTheProject")
  handleSectionChange() {
    this.setDeviceList();
  }
    * / 
  /*----------  Getters  ----------*/

  get formatedDevices() {
    return this.listOfDevices;
  }

  /*----------  Methods  ----------*/
  async setDeviceList() {
    this.loading = true;
    this.listOfDevices = [];
    let totalDefault: number = 0;
    let totalOposite: number = 0;
    if (this.CurrentProject.isRZP) {
      totalOposite = this.allSectionsInTheProject.reduce((acc: number, curent: Section) => {
        if (curent.direction === "OPPOSITE") {
          acc++;
        }
        return acc;
      }, 0);
      totalDefault = this.allSectionsInTheProject.reduce((acc: number, curent: Section) => {
        if (curent.direction === "DEFAULT") {
          acc++;
        }
        return acc;
      }, 0);
    }
    this.allSectionsInTheProject.map((sec: Section, index: number) => {
      if (sec.boardOne) {
        let formated = this.updateDevice(sec.state.stateDetails.deviceStateOne);
        this.listOfDevices.push({
          id: sec.boardOne,
          sectionId: sec.id,
          position: "deviceOne",
          kilometrage: sec.kilometrage,
          side: sec.type?.name !== "display" ? "right" : "left",
          orderedSection: this.CurrentProject.isRZP
            ? sec.direction === "OPPOSITE"
              ? sec.ordinalNumber - totalOposite
              : totalDefault + 1 - sec.ordinalNumber
            : index + 1,
          ...formated
        });
      }
      if (sec.boardTwo) {
        let formated = this.updateDevice(sec.state.stateDetails.deviceStateTwo);
        this.listOfDevices.push({
          id: sec.boardTwo,
          sectionId: sec.id,
          position: "deviceTwo",
          kilometrage: sec.kilometrage,
          side: "right",
          orderedSection: this.CurrentProject.isRZP
            ? sec.direction === "OPPOSITE"
              ? sec.ordinalNumber - totalOposite
              : totalDefault + 1 - sec.ordinalNumber
            : index + 1,
          ...formated
        });
      }
    });

    // await this.GET_LATEST_STATE(this.CurrentProject.id);
    this.formatDevicesInformation();
    this.loading = false;
  }

  translateTableHeaderLabel(label: string) {
    return this.$t(`project.tableLabels.projectDeviceListLabels.${label}`);
  }
  updateDevice({ picture, brightness, deviceBatteryState, status }: SingleDeviceState) {
    return {
      picture,
      brightness,
      deviceBatteryState,
      status
    };
  }

  HandleOpenInformationModal(item: any) {
    this.SET_MODAL_OPEN({ sectionId: item.sectionId, position: item.position });
    this.$bvModal.show("openDisplayModalDetails");
  }
  ModalClose() {
    this.modal = {
      sectionId: 0,
      position: "deviceOne"
    };
    this.$bvModal.hide("openDisplayModalDetails");
  }
}
