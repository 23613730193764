
/**
 * Battery list tab view,
 * the table of Battery the current project,
 * all formatting and display information for the Battery list.
 *
 *
 * @author Rami Aqqad <Ibomade GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import Project from "@/model/Project";
import { BatteryDevice, DeviceState } from "@/model/SingleDeviceModal";
import AddBatteryToProject from "@/components/project/SingleProject/Battery/AddBatteryToProject.vue";
import { DeviceProject } from "@/interface/DeviceInterface";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";

interface Status {
  class: string;
  msg: string;
}
@Component({
  name: "BatteryListTabView",
  components: { AddBatteryToProject },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters({
      getAllDevices: "getAllDevices",
      getAllBatteryDevicesInProject: "getAllBatteryDevicesInProject",
      getAllBatteryDevices: "getAllBatteryDevices",
      getDeviceById: "getDeviceById"
    }),
    ...mapGetters("projects", {
      CurrentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd", "updateDevice", "updateGpsPositionDevice"])
  }
})
export default class BatteryListTabView extends Mixins(confirmModal) {
  /******Props *****/
  listOfDevices: Array<BatteryDevice> = [];

  /******Vuex *****/
  readonly getUserAccess!: UserAccess;
  readonly getDeviceById!: any;
  readonly CurrentProject!: Project;
  readonly getAllDevices!: Array<BatteryDevice>;
  readonly getAllBatteryDevices!: Array<BatteryDevice>;
  getAllBatteryDevicesInProject!: Function;
  private getAllDevicesFromBackEnd!: Function;
  updateDevice!: Function;
  updateGpsPositionDevice!: Function;

  /******localState *****/
  labelsForDeviceList: Array<string> = ["id", "serialNumber", "emnifyIccId", "gps", "gpsLastUpdated", "actions"];
  updateStatus: null | Status = null;
  /*----------  Vue watchers  ----------*/

  /*----------  Getters  ----------*/

  get formatedBatteryDevices() {
    return this.listOfDevices;
  }

  async created() {
    await this.getAllDevicesFromBackEnd();
    await this.getAllBatteryDevicesInProject(this.CurrentProject.id);
  }

  emitUpdateStatus(status: object) {
    this.$emit("updateStatus", status);
  }

  translateTableHeaderLabel(label: string) {
    return this.$t(`inventory.tableLabels.${label}`);
  }

  removeBatteryFromProject(batteryId: number) {
    this.updateStatus = null;
    this.$emit("updateStatus", null);
    this.confirm(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmRemoveBattery", {
        id: batteryId
      }),
      async () => {
        try {
          const device = this.getDeviceById(batteryId);
          var parseCurrenProjectStatus = JSON.parse(JSON.stringify(this.CurrentProject.status));

          const projectDataInDevice: DeviceProject = { id: this.CurrentProject.id, name: this.CurrentProject.name, statusId: parseCurrenProjectStatus.id};
          device.projects.pop(projectDataInDevice);
          device.state = device.projects.length === 0 ? DeviceState.AVAILABLE : DeviceState.IN_FIELD;

          let updateBatteryInProjectStatus = await this.updateDevice(device);
          if (updateBatteryInProjectStatus.status === 202) {
            //this.GetCurrentProject(this.CurrentProject.id);
            this.$emit("updateStatus", {
              class: "success",
              msg: (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.removeBatterySuccess")
            });
          } else {
            this.$emit("updateStatus", {
              class: "success",
              msg: (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.removeBatterySuccess")
            });
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "danger",
            msg: error
          });
        }
      }
    );
  }

  handleCallGpsForAllBatteriesInThisProject() {
    this.updateStatus = null;
    this.$emit("updateStatus", null);

    let updateBatteryInProjectStatus: any = null;
    this.confirm(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmCallGpsAllBatteries", {}),
      async () => {
        try {
          let batteriesInThisProject = this.getAllBatteryDevicesInProject(this.CurrentProject.id);

          for (const battery of batteriesInThisProject) {
            updateBatteryInProjectStatus = await new Promise((resolve, reject) => {
              this.updateGpsPositionDevice(battery.serialNumber)
                .then((response: any) => resolve(response))
                .catch((error: any) => reject(error));
            });

            if (
              updateBatteryInProjectStatus.status === 202 &&
              this.checkGPSResponseAvailability(updateBatteryInProjectStatus)
            ) {
              this.$emit("updateStatus", {
                class: "success",
                msg:
                  (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSSuccess") +
                  "(" +
                  this.translateTableHeaderLabel("serialNumber") +
                  ": " +
                  updateBatteryInProjectStatus.data.serialNumber +
                  ")"
              });
            } else {
              this.$emit("updateStatus", {
                class: "danger",
                msg:
                  (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSFaild") +
                  " (" +
                  this.translateTableHeaderLabel("serialNumber") +
                  ": " +
                  updateBatteryInProjectStatus.data.serialNumber +
                  " / SMS status: " +
                  updateBatteryInProjectStatus.data.gpsSmsStatusDescription +
                  " ) "
              });
            }
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "danger",
            msg: error
          });
        }
      }
    );
  }

  checkGPSResponseAvailability(response: any) {
    return (
      (response.data.latitude !== 0 || response.data.longitude !== 0) &&
      (response.data.gpsSmsStatusDescription === "DELIVERY ATTEMPT PENDING" ||
        response.data.gpsSmsStatusDescription === "DELIVERED")
    );
  }
  callGps(serialNumber: String) {
    this.updateStatus = null;
    this.$emit("updateStatus", null);
    this.confirm(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmCallGps", {
        id: serialNumber
      }),
      async () => {
        try {
          let updateBatteryInProjectStatus = await this.updateGpsPositionDevice(serialNumber);
          if (
            updateBatteryInProjectStatus.status === 202 &&
            this.checkGPSResponseAvailability(updateBatteryInProjectStatus)
          ) {
            this.$emit("updateStatus", {
              class: "success",
              msg:
                (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSSuccess") +
                "(" +
                this.translateTableHeaderLabel("serialNumber") +
                ": " +
                updateBatteryInProjectStatus.data.serialNumber +
                ")"
            });
          } else {
            this.$emit("updateStatus", {
              class: "danger",
              msg:
                (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSFaild") +
                " (" +
                this.translateTableHeaderLabel("serialNumber") +
                ": " +
                updateBatteryInProjectStatus.data.serialNumber +
                " / SMS status: " +
                updateBatteryInProjectStatus.data.gpsSmsStatusDescription +
                " ) "
            });
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "danger",
            msg: error
          });
        }
      }
    );
  }
}
