
/**
 * Battery list tab view,
 * the table of Battery the current project,
 * all formatting and display information for the Battery list.
 *
 *
 * @author Rami Aqqad <Ibomade GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapActions } from "vuex";

import Multiselect from "vue-multiselect";
import Project from "@/model/Project";
import { BatteryDevice, DeviceState } from "@/model/SingleDeviceModal";
import { Prop } from "vue-property-decorator";
import { DeviceProject } from "@/interface/DeviceInterface";

//!Interface
interface UpdateStatus {
  class: string;
  msg: string;
}

// ! Components
@Component({
  name: "AddBatteryToProject",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(["getDeviceById", "getAllBatteryDevicesNotInProject"]),
    ...mapGetters("projects", {
      CurrentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions(["updateDevice"])
  }
})
export default class AddBatteryToProject extends Vue {
  /*----------  Props  ----------*/
  @Prop({ type: Array, default: () => [] }) allBatteryDevices!: Array<BatteryDevice>;

  /*****  Vuex ******/
  readonly getDeviceById!: any;
  readonly CurrentProject!: Project;
  updateDevice!: Function;
  getAllBatteryDevicesNotInProject!: Function;

  /*****  LocalState ******/
  updateStatus: UpdateStatus | null = null;
  loading = false;
  batteriesSelected: [] = [];

  /*****  vue life cycles ******/
  created() {
    //console.log("Hello");
    //console.log(this.allBatteryDevices);
  }
  /*****  HelperFunction ******/
  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.title"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        action(value);
      });
  }

  /*****  Methods ******/
  handleAddBatteriesToThisProject() {
    this.updateStatus = null;
    this.confirmModal(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmAddBattery"),
      async (value: boolean) => {
        this.$emit("updateStatus", null);
        if (value) {
          this.loading = true;
          let batteriesId: Array<string> = this.batteriesSelected.map((batteryDevice: BatteryDevice): string => {
            let id = batteryDevice.id;
            return id;
          });
          var parseCurrenProjectStatus = JSON.parse(JSON.stringify(this.CurrentProject.status));
          const projectDataInDevice: DeviceProject = { id: this.CurrentProject.id, name: this.CurrentProject.name, statusId: parseCurrenProjectStatus.id};

          let assignBatteriesToProjectStatus = this.assignBatteriesToProject(projectDataInDevice, batteriesId);
          this.batteriesSelected = [];
          if (assignBatteriesToProjectStatus === 202) {
            this.$emit("updateStatus", {
              class: "success brand-green",
              msg: this.$t("project.singleProject.battery.modalAddBatteryToProject.batteryAddedSuccess")
            });
            //this.GetProjectInformationLatest();
            this.$bvModal.hide("AddBatteryToProject");
          } else {
            this.$emit("updateStatus", {
              class: "danger",
              msg: this.$t("project.singleProject.battery.modalAddBatteryToProject.batteryAddedFailed")
            });
          }
          this.loading = false;
        }
        return;
      }
    );
  }

  GetLatestBetterylist() {
    this.updateStatus = null;
    this.getAllBatteryDevicesNotInProject(this.CurrentProject.id);
  }

  assignBatteriesToProject(projectInfoInDevice: DeviceProject, batteriesId: Array<string>) {
    let updateStatus = 202;
    batteriesId.forEach(async (batteryId) => {
      const device = this.getDeviceById(batteryId);
      const isBatteryAlreadyAssignedToThisProject = device.projects.some(
        (projectInfo: { id: number }) => projectInfo.id === projectInfoInDevice.id
      );
      if (!isBatteryAlreadyAssignedToThisProject) {
        device.projects.push(projectInfoInDevice);

        device.state = this.CurrentProject.isProjectActive
          ? DeviceState.IN_FIELD
          : this.CurrentProject.isProjectPlaning || this.CurrentProject.isProjectDismantling
          ? DeviceState.BOOKED
          : DeviceState.AVAILABLE;
        const updatedDevice = await this.updateDevice(device);

        if (updatedDevice.status !== 202) {
          updateStatus = updatedDevice.status;
          return updateStatus;
        }
      }
    });
    return updateStatus;
  }
}
